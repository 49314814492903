<template>
  <div class="container" data-container="navigation">
    <nav class="navigation">
      <div class="nav navigation__nav">
        <div class="nav__list">
          <template v-for="(item, index) in menuItems" :key="index">
            <template v-if="item.isModal">
              <router-link :to="item.router" :data-inner-select="item.id" class="nav__link">
                <div class="nav__ico">
                  <img :src="item.imgSrc" alt="i1" class="nav__img" />
                </div>
                <div class="nav__content">
                  <div class="nav__name">{{ item.name }}</div>
                  <div class="nav__desc">{{ item.description }}</div>
                </div>
              </router-link>
            </template>
            <template v-else>
              <a :href="item.link" target="_blank" class="nav__link">
                <div class="nav__ico">
                  <img :src="item.imgSrc" alt="i1" class="nav__img" />
                </div>
                <div class="nav__content">
                  <div class="nav__name">{{ item.name }}</div>
                  <div class="nav__desc">{{ item.description }}</div>
                </div>
              </a>
            </template>
          </template>
        </div>
      </div>
    </nav>
    <!-- END  navigation -->
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  props: {
    menuItems: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
