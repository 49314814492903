import axios from 'axios';
import cfg_axios from '@/config/cfg_axios';
import { insertMedia } from '@/assets/js/insertMedia';

export default {
  /**
   * ACTIONS
   */
  actions: {
    update_media_news({ commit }, news) {
      commit('UPDATE_NEWS', news);
    },
    update_media_events({ commit }, events) {
      commit('UPDATE_EVENTS', events);
    },
    update_media_topics({ commit }, topics) {
      commit('UPDATE_TOPICS', topics);
    },
    init_streams({ commit }) {
      try {
        if (localStorage.streams && localStorage.streams_expired) {
          commit('UPDATE_STREAMS', JSON.parse(localStorage.streams));
          commit('UPDATE_STREAMS_EXPIRED', localStorage.streams_expired);
        }
      } catch (e) {
        // console.log(e.message);
      }
    },
    async fetch_streams({ commit, state }) {
      const response = await axios.get(cfg_axios.base_url + '/streams');
      const data = response.data;

      let streams = [
        {
          // STREAM ADD [BUTTON]!!!
          isStream: false,
          isActive: false,
          isFullscreen: false,
          options: {
            type: 'youtube',
            src: '',
            setting: 'mute=1&autoplay=0',
          },
        },
      ];
      commit('UPDATE_STREAMS', streams);

      data.forEach(stream => {
        commit('ADD_STREAM', stream);
      });

      commit('UPDATE_STREAMS', state.streams);
      commit('UPDATE_STREAMS_EXPIRED', new Date(new Date().getTime() + 5 * 60 * 1000).getTime());

      insertMedia({
        delay: 0,
        immediately: true,
        attr: 'data-insertmedia',
      });

      commit('UPDATE_FETCHING_STREAMS', false);
    },
  },
  /**
   * MUTATIONS
   */
  mutations: {
    UPDATE_NEWS(state, news) {
      try {
        localStorage.news = JSON.stringify(news);
        state.news = news;
      } catch (e) {
        state.news = news;
      }
    },
    UPDATE_EVENTS(state, events) {
      try {
        localStorage.events = JSON.stringify(events);
        state.events = events;
      } catch (e) {
        state.events = events;
      }
    },
    UPDATE_TOPICS(state, topics) {
      try {
        localStorage.topics = JSON.stringify(topics);
        state.topics = topics;
      } catch (e) {
        state.topics = topics;
      }
    },
    UPDATE_SOCIAL_ICONS(state, social_icons) {
      state.social_icons = social_icons;
    },
    UPDATE_STREAMS(state, streams) {
      try {
        localStorage.streams = JSON.stringify(streams);
        state.streams = streams;
      } catch (e) {
        state.streams = streams;
      }
    },
    UPDATE_STREAMS_EXPIRED(state, expired) {
      try {
        localStorage.streams_expired = expired;
        state.streams_expired = expired;
      } catch (e) {
        state.streams_expired = expired;
      }
    },
    ADD_STREAM(state, stream) {
      let setting = '';
      if (stream.type === 'youtube') {
        setting = 'mute=0&autoplay=0';
      } else {
        setting = 'muted=false&autoplay=false';
      }
      state.streams.push({
        isStream: true,
        isActive: stream.active,
        isFullscreen: stream.id >= 5,
        options: {
          type: stream.type,
          src: stream.src,
          setting: setting,
        },
      });
    },
    UPDATE_VIDEOS(state, videos) {
      state.videos = videos;
    },
    UPDATE_GALLERY(state, gallery) {
      state.gallery = gallery;
    },
    UPDATE_FETCHING_STREAMS(state, fetching) {
      state.fetching_streams = fetching;
    },
  },
  /**
   * STATE
   */
  state: {
    fetching_streams: true,
    news: [],
    events: [],
    topics: [],
    social_icons: [],
    streams_expired: null,
    streams: [
      {
        // STREAM ADD [BUTTON]!!!
        isStream: false,
        isActive: false,
        isFullscreen: false,
        options: {
          type: 'youtube',
          src: '',
          setting: 'mute=1&autoplay=0',
        },
      },
    ],
    videos: [],
    gallery: [],
    social_links: [
      {
        alt: 'googlePlus',
        href: 'googlePlus',
        imgSrc: require('@/assets/images/share/google-plus.png'),
        width: 15,
        height: 14,
      },
      {
        alt: 'twitter',
        href: 'twitter',
        imgSrc: require('@/assets/images/share/twitter.png'),
        width: 16,
        height: 14,
      },
      {
        alt: 'vk',
        href: 'vk',
        imgSrc: require('@/assets/images/share/vk.png'),
        width: 16,
        height: 14,
      },
      {
        alt: 'rss',
        href: 'rss',
        imgSrc: require('@/assets/images/share/rss.png'),
        width: 15,
        height: 14,
      },
      {
        alt: 'facebook',
        href: 'facebook',
        imgSrc: require('@/assets/images/share/facebook.png'),
        width: 16,
        height: 14,
      },
      {
        alt: 'ok-alt',
        href: 'ok',
        imgSrc: require('@/assets/images/share/ok.png'),
        width: 15,
        height: 14,
      },
    ],
  },
  /**
   * GETTERS
   */
  getters: {
    get_media_news(state) {
      return state.news;
    },
    get_media_events(state) {
      return state.events;
    },
    get_media_topics(state) {
      return state.topics;
    },
    get_media_social_icons(state) {
      return state.social_icons;
    },
    get_media_social_links(state) {
      return state.social_links;
    },
    get_media_streams(state) {
      return state.streams;
    },
    get_media_videos(state) {
      return state.videos;
    },
    get_media_gallery(state) {
      return state.gallery;
    },
    get_media_fetching_streams(state) {
      return state.fetching_streams;
    },
    get_streams_expired(state) {
      return state.streams_expired;
    },
  },
};
