const yt_widget = 'yt-widget';
export default {
  actions: {
    update_lang({ commit }, lang) {
      commit('UPDATE_LANG', lang);
    },
    update_flag({ commit }, flag) {
      commit('UPDATE_FLAG', flag);
    },
  },
  mutations: {
    INIT_LANG(state) {
      try {
        if (!localStorage.getItem('yt-widget') || !localStorage.flag) {
          localStorage.clear();
          this.commit('UPDATE_LANG', state.origin_lang);
          this.commit('UPDATE_FLAG', state.flag);
        } else {
          this.commit('UPDATE_LANG', JSON.parse(localStorage.getItem('yt-widget')).lang);
          this.commit('UPDATE_FLAG', localStorage.flag);
        }
      } catch (e) {
        console.log(
          'Cookies/Local storage - not allowed, so you can have problems with website language translations and' +
            ' footer open/close state, we are sorry, we do the best to make your experience better even without cookies!'
        );
        this.commit('UPDATE_LANG', state.origin_lang);
      }
    },
    UPDATE_LANG(state, lang) {
      try {
        localStorage.setItem(
          yt_widget,
          JSON.stringify({
            lang: lang,
            active: true,
          })
        );
        state.lang = lang;
      } catch (e) {
        state.lang = lang;
      }
    },
    UPDATE_FLAG(state, flag) {
      try {
        localStorage.flag = flag;
        state.flag = flag;
      } catch (e) {
        state.flag = flag;
      }
    },
  },
  getters: {
    get_lang: state => {
      try {
        return JSON.parse(localStorage.getItem(yt_widget)).lang;
      } catch (e) {
        return state.lang;
      }
    },
    get_flag: state => {
      try {
        return localStorage.flag;
      } catch (e) {
        return state.flag;
      }
    },
    get_languages: state => state.languages,
  },
  state: {
    lang: 'en',
    flag: 'en',
    origin_lang: 'en',
    languages: [
      {
        dataLang: 'lv',
        imgSuffix: 'lv',
        name: 'Latviešu',
      },
      {
        dataLang: 'sv',
        imgSuffix: 'sv',
        name: 'Svenska',
      },
      {
        dataLang: 'az',
        imgSuffix: 'az',
        name: 'Azərbaycan',
      },
      {
        dataLang: 'pt',
        imgSuffix: 'bras',
        name: 'Brasileiro',
      },
      {
        dataLang: 'de',
        imgSuffix: 'de',
        name: 'Deutsch',
      },
      {
        dataLang: 'hy',
        imgSuffix: 'hy',
        name: 'Hայերեն',
      },
      {
        dataLang: 'en',
        imgSuffix: 'usa',
        name: 'American',
      },
      {
        dataLang: 'et',
        imgSuffix: 'et',
        name: 'Eestlane',
      },
      {
        dataLang: 'da',
        imgSuffix: 'da',
        name: 'Dansk',
      },
      {
        dataLang: 'pt',
        imgSuffix: 'pt',
        name: 'Português',
      },
      {
        dataLang: 'uk',
        imgSuffix: 'uk',
        name: 'Українська',
      },
      {
        dataLang: 'fr',
        imgSuffix: 'fr',
        name: 'Français',
      },
      {
        dataLang: 'es',
        imgSuffix: 'es',
        name: 'Español',
      },
      {
        dataLang: 'pl',
        imgSuffix: 'pl',
        name: 'Polski',
      },
      {
        dataLang: 'ja',
        imgSuffix: 'ja',
        name: '日本語',
      },
      {
        dataLang: 'el',
        imgSuffix: 'el',
        name: 'Ελληνικά',
      },
      {
        dataLang: 'cs',
        imgSuffix: 'cs',
        name: 'Češka',
      },
      {
        dataLang: 'ko',
        imgSuffix: 'ko',
        name: '한국어',
      },
      {
        dataLang: 'ru',
        imgSuffix: 'ru',
        name: 'Русский',
      },
      {
        dataLang: 'tr',
        imgSuffix: 'tr',
        name: 'Türk',
      },
      {
        dataLang: 'zh',
        imgSuffix: 'zh',
        name: '中文',
      },
      {
        dataLang: 'en',
        imgSuffix: 'en',
        name: 'English',
        classList: 'hint__item_rr',
      },
      {
        dataLang: 'lt',
        imgSuffix: 'lt',
        name: 'Lietuvos',
      },
      {
        dataLang: 'no',
        imgSuffix: 'no',
        name: 'Norsk',
        classList: 'hint__item_rl',
      },
    ],
  },
};
