import axios from 'axios';
import cfg_axios from '@/config/cfg_axios';

// Constants
const API_ENDPOINTS = {
  serverList: `${process.env.VUE_APP_API_BASE_URL}/api/v1/server/list`,
};

export default {
  /**
   * ACTIONS
   */
  actions: {
    async fetch_servers({ commit }) {
      try {
        const { data } = await axios.get(API_ENDPOINTS.serverList, {
          headers: cfg_axios.headers,
        });

        commit('UPDATE_SERVERS', data);
        return data;
      } catch (e) {
        console.error('fetch_servers error:', e);
        throw e;
      }
    },
    form_change_server({ commit }, id) {
      commit('UPDATE_CURRENT_SERVER', id);
    },
    update_server_option({ commit }, option) {
      commit('UPDATE_SERVER_OPTION', option);
    },
    update_servers_links({ commit }, server_links) {
      commit('UPDATE_SERVER_LINKS', server_links);
    },
    update_default_server({ commit }, server) {
      commit('UPDATE_DEFAULT_SERVER', server);
    },
    update_current_server({ commit }, server) {
      commit('UPDATE_CURRENT_SERVER', server);
    },
  },
  /**
   * MUTATIONS
   */
  mutations: {
    UPDATE_SERVERS(state, servers) {
      state.servers = servers;
    },
    UPDATE_CURRENT_SERVER(state, server) {
      state.current_server = server;
    },
    UPDATE_DEFAULT_SERVER(state, server) {
      state.default_server = server;
    },
    UPDATE_SERVER_OPTION(state, option) {
      state.server_option = option;
    },
    UPDATE_SERVER_LINKS(state, serverLinks) {
      state.server_links = serverLinks;
    },
  },
  /**
   * STATE
   */
  state: {
    current_server: null,
    servers: [],
    server_links: [
      {
        id: 'signup',
        name: 'Registration',
        routeName: 'Signup',
        class: 'hint__item hint__item_rl hint__item_rr',
        external: false,
        href: false,
      },
      {
        id: 'description',
        name: 'Description',
        routeName: 'Description',
        class: 'hint__item',
        external: true,
        href: '',
      },
      {
        id: 'vote',
        name: 'Vote',
        routeName: 'Vote',
        class: 'hint__item',
        external: false,
        href: false,
      },
      {
        id: 'stats',
        name: 'Statistics',
        routeName: 'Statistics',
        class: 'hint__item',
        external: false,
        href: false,
      },
      {
        id: 'support',
        name: 'Support',
        routeName: 'Support',
        class: 'hint__item',
        external: false,
        href: false,
      },
      {
        id: 'shop',
        name: 'Shop',
        routeName: 'Shop',
        class: 'hint__item',
        external: false,
        href: false,
      },
    ],
    default_server: 0,
    server_option: null,
    default_server_option: 'about',
  },
  /**
   * GETTERS
   */
  getters: {
    get_servers(state) {
      return state.servers;
    },
    get_current_server(state) {
      return state.current_server || state.default_server;
    },
    get_default_server(state) {
      return state.default_server;
    },
    get_server_option(state) {
      return state.server_option;
    },
    get_default_server_option(state) {
      return state.default_server_option;
    },
    get_server_links(state) {
      return state.server_links;
    },
  },
};
