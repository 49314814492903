<template>
  <footer class="section" data-section="footer">
    <div class="container" data-container="footer" data-toggle-footer-container>
      <div class="footer">
        <div class="footer__nav">
          <div class="f-nav footer__f-nav">
            <div class="footer__title">Services</div>
            <div class="f-nav__list">
              <router-link
                :to="{ name: 'RestorePassword' }"
                class="f-nav__item"
                data-inner-select="restorePassword"
                >Restore password</router-link
              >
              <router-link
                :to="{ name: 'ChangePassword' }"
                class="f-nav__item"
                data-inner-select="changePassword"
                >Change password</router-link
              >
              <router-link
                :to="{ name: 'RestoreUsername' }"
                class="f-nav__item"
                data-inner-select="restoreUsername"
                >Restore ID</router-link
              >
              <router-link
                :to="{ name: 'ChangeEmail' }"
                class="f-nav__item"
                data-inner-select="changeEmail"
                >Change E-mail</router-link
              >
              <router-link :to="{ name: 'Unbind' }" class="f-nav__item" data-inner-select="unbind"
                >Unbind HWID/IP</router-link
              >
            </div>
          </div>
          <div class="f-nav footer__f-nav">
            <div class="footer__title">Support</div>
            <div class="f-nav__list">
              <router-link
                :to="{ name: 'Contacts' }"
                class="f-nav__item"
                data-inner-select="contacts"
                >Contacts</router-link
              >
              <a href="https://forum.la2dream.com/forum/5-faq/" target="_blank" class="f-nav__item"
                >Faq</a
              >
              <a :href="sever_description" target="_blank" class="f-nav__item">Information</a>
              <router-link :to="{ name: 'Vote' }" class="f-nav__item" data-inner-select="vote"
                >Vote</router-link
              >
              <router-link
                :to="{
                  name: 'Shop',
                  params: {
                    id:
                      this.$store.getters.get_current_server ||
                      this.$store.getters.get_default_server,
                  },
                }"
                class="f-nav__item"
                data-inner-select="shop"
                >Shop</router-link
              >
            </div>
          </div>
          <div class="f-nav footer__f-nav">
            <div class="footer__title">More</div>
            <div class="f-nav__list">
              <router-link :to="{ name: 'Rules' }" class="f-nav__item" data-inner-select="rules"
                >Game rules</router-link
              >
              <router-link
                :to="{ name: 'UserAgreement' }"
                class="f-nav__item"
                data-inner-select="user_agreement"
                >User agreement</router-link
              >
              <router-link
                :to="{ name: 'LicenseAgreement' }"
                class="f-nav__item"
                data-inner-select="license_agreement"
                >License agreement</router-link
              >
              <router-link
                :to="{ name: 'G2AAgreement' }"
                class="f-nav__item"
                data-inner-select="g2a_agreement"
                >Additional conditions ZEN (G2A)</router-link
              >
              <router-link
                :to="{ name: 'Statistics' }"
                data-inner-select="stats"
                class="f-nav__item"
                >Statistics</router-link
              >
            </div>
          </div>
        </div>
        <!-- END  footer__nav -->
        <div class="footer__info">
          <div class="copyright">
            <div class="copyright__txt">
              <span class="copyright__site">© {{ new Date().getFullYear() }} LA2DREAM </span>
              - IT'S ONLY TEST SERVER, ALL RIGHT'S BELONG TO NCSOFT.
            </div>
            <router-link
              :to="{ name: 'PrivacyAgreement' }"
              class="copyright__policy"
              data-inner-select="privacy_agreement"
              >PRIVACY POLICY</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'FooterComponent',
  data() {
    return {
      speed: 300,
      body: $('body'),
      container: $('[data-toggle-footer-container]'),
    };
  },
  computed: {
    sever_description() {
      return this.$store.getters.get_server_description;
    },
  },
  methods: {
    toggle_footer() {
      this.$store.dispatch('toggle_footer');
    },
    footer_switcher(footer) {
      switch (footer) {
        case 0:
          $('[data-toggle-footer-container]').slideUp(this.speed, () => {
            if (!$('body').hasClass('footer-hide')) {
              $('body').addClass('footer-hide');
            }
            $(window).trigger('resize');
          });
          break;
        case 1:
          $('[data-toggle-footer-container]').slideDown(this.speed, () => {
            if ($('body').hasClass('footer-hide')) {
              $('body').removeClass('footer-hide');
            }
            $(window).trigger('resize');
          });
          break;
        default:
          console.error('switch default;');
          break;
      }
    },
  },
  beforeMount() {
    this.$store.dispatch('init_footer');
  },
  mounted() {
    let footer = null;

    try {
      footer =
        parseInt(this.$store.getters.get_footer) ||
        parseInt(this.$store.getters.get_footer_default);
      this.footer_switcher(footer);
    } catch (e) {
      footer = parseInt(this.$store.getters.get_footer_default);

      if (footer === 1) {
        this.container.slideUp(this.speed, () => {
          if (!this.body.hasClass('footer-hide')) {
            this.body.addClass('footer-hide');
          }
          $(window).trigger('resize');
        });
      }

      if (footer === 0) {
        this.container.slideDown(this.speed, () => {
          if (this.body.hasClass('footer-hide')) {
            this.body.removeClass('footer-hide');
          }
          $(window).trigger('resize');
        });
      }
    }
  },
};
</script>

<style scoped>
.copyright__policy {
  font-size: 0.75em;
}
</style>
