import axios from 'axios';
import cfg_axios from '@/config/cfg_axios';
import { __config } from '@/assets/js/config';

// Constants
const CACHE_EXPIRATION_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds
const API_ENDPOINTS = {
  config: `${process.env.VUE_APP_API_BASE_URL}/api/v1/config`,
  forum: `${process.env.VUE_APP_API_BASE_URL}/api/v3/forum`,
};

export default {
  /**
   * ACTIONS
   */
  actions: {
    /**
     * Initialize store from cache if available
     */
    init({ dispatch }) {
      dispatch('init_config');
      dispatch('init_forum');
    },
    async set_default_configs({ dispatch, getters }) {
      try {
        const config = getters.get_config;
        if (!config?.public) return;

        const server_id = await dispatch('find_config_item', 'default_server_id');
        if (server_id) {
          await dispatch('update_default_server', server_id);
        }
      } catch (e) {
        console.error('set_default_configs error:', e);
      }
    },
    find_config_item({ getters }, key) {
      try {
        const config = getters.get_config;
        if (!config?.public) return null;

        const item = config.public.find(item => item.key === key);
        return item?.value ?? null;
      } catch (e) {
        console.error(`Could not find config item '${key}'`, e);
        return null;
      }
    },
    init_config({ commit, dispatch }) {
      try {
        if (localStorage.config && localStorage.config_expired) {
          const config = JSON.parse(localStorage.config);
          commit('UPDATE_CONFIG', config);
          commit('UPDATE_SERVERS', config.servers);
          commit('UPDATE_CONFIG_EXPIRED', localStorage.config_expired);
          dispatch('set_default_configs', config.public);
        }
      } catch (e) {
        // console.log(e.message);
      }
    },
    init_forum({ dispatch, commit }) {
      try {
        if (
          localStorage.news &&
          localStorage.events &&
          localStorage.topics &&
          localStorage.forum_expired
        ) {
          dispatch('update_media_news', JSON.parse(localStorage.news));
          dispatch('update_media_events', JSON.parse(localStorage.events));
          dispatch('update_media_topics', JSON.parse(localStorage.topics));
          commit('UPDATE_FORUM_EXPIRED', localStorage.forum_expired);
        }
      } catch (e) {
        // console.log(e.message);
      }
    },
    async fetch_config({ commit, dispatch }) {
      try {
        const { data } = await axios.get(API_ENDPOINTS.config, {
          headers: cfg_axios.headers,
        });

        const expirationTime = new Date(new Date().getTime() + CACHE_EXPIRATION_TIME).getTime();

        commit('UPDATE_CONFIG', data);
        commit('UPDATE_CONFIG_EXPIRED', expirationTime);
        await dispatch('set_default_configs');

        return data;
      } catch (e) {
        console.error('fetch_config error:', e);
        throw e;
      }
    },
    async fetch_forum({ commit, dispatch }) {
      try {
        const { data } = await axios.get(API_ENDPOINTS.forum, {
          headers: cfg_axios.headers,
        });

        const expirationTime = new Date(new Date().getTime() + CACHE_EXPIRATION_TIME).getTime();

        if (data.news) dispatch('update_media_news', data.news);
        if (data.events) dispatch('update_media_events', data.events);
        if (data.topics) dispatch('update_media_topics', data.topics);
        commit('UPDATE_FORUM_EXPIRED', expirationTime);

        return data;
      } catch (e) {
        console.error('fetch_forum error:', e);
        throw e;
      }
    },
  },
  /**
   * MUTATIONS
   */
  mutations: {
    UPDATE_CONFIG(state, config) {
      if (!config) return;

      try {
        localStorage.config = JSON.stringify(config);
        state.config = config;
      } catch (e) {
        console.error('Error saving config to localStorage:', e);
        state.config = config;
      }
    },
    UPDATE_CONFIG_EXPIRED(state, expired) {
      try {
        localStorage.config_expired = expired;
        state.config_expired = expired;
      } catch (e) {
        state.config_expired = expired;
      }
    },
    UPDATE_FORUM_EXPIRED(state, expired) {
      try {
        localStorage.forum_expired = expired;
        state.forum_expired = expired;
      } catch (e) {
        state.forum_expired = expired;
      }
    },
  },
  /**
   * STATE
   */
  state: {
    config: null,
    config_expired: null,
    forum_expired: null,
    server_description: __config.server.server_desc_by_id[2] || null,
  },
  /**
   * GETTERS
   */
  getters: {
    get_config(state) {
      return state.config;
    },
    get_config_expired(state) {
      return state.config_expired;
    },
    get_forum_expired(state) {
      return state.forum_expired;
    },
    get_server_description(state) {
      return state.server_description;
    },
  },
};
