<template>
  <div class="section" data-section="under">
    <div class="footer-switcher" @click="toggleFooter" data-toggle-footer>
      <div class="hint__btn hint__btn_cursor_pointer">
        <div class="hint__arrow footer-switcher__arrow"></div>
      </div>
    </div>
    <div class="box">
      <div class="logotype logotype_small">
        <a href="/" class="logo" title="La2Dream - Lineage 2 Server">
          <img src="@/assets/images/logo/logo.webp" alt="La2Dream Logo" />
        </a>
      </div>
      <div class="container">
        <div class="under">
          <div class="under__ps ps">
            <div v-for="payment in paymentSystems" :key="payment.name" class="ps__item">
              <img :src="payment.image" :alt="payment.description" class="ps__img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import paypalImg from '@/assets/images/ps/paypal.png';
import g2aImg from '@/assets/images/ps/g2a.png';
import paymentwallImg from '@/assets/images/ps/paymentwall.png';
import freekassaImg from '@/assets/images/ps/freekassa.png';
import payopImg from '@/assets/images/ps/payop.png';

/**
 * Footer component displaying supported payment systems
 * @vue-component
 */
export default {
  name: 'FooterPayments',
  data() {
    return {
      paymentSystems: [
        {
          name: 'paypal',
          image: paypalImg,
          description: 'Pay with PayPal',
        },
        {
          name: 'g2a',
          image: g2aImg,
          description: 'Pay with G2A',
        },
        {
          name: 'paymentwall',
          image: paymentwallImg,
          description: 'Pay with PaymentWall',
        },
        {
          name: 'freekassa',
          image: freekassaImg,
          description: 'Pay with FreeKassa',
        },
        {
          name: 'payop',
          image: payopImg,
          description: 'Pay with PayOp',
        },
      ],
    };
  },
  methods: {
    /**
     * Toggle footer visibility
     */
    toggleFooter() {
      this.$store.dispatch('toggle_footer');
    },
  },
};
</script>
