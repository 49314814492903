const $ = require('jquery');
import router from '../../router';

export default {
  /**
   * ACTIONS
   */
  actions: {
    check_modal_dropdown({ commit, getters }, target) {
      let modal_dropdown = null;
      for (let i = 0; i < getters.get_modal_selector_options.length; i++) {
        if (getters.get_modal_selector_options[i].id === target) {
          modal_dropdown = true;
          break;
        }
      }
      commit('UPDATE_MODAL_DROPDOWN', modal_dropdown);
    },
    update_is_modal_wide({ commit }, value) {
      let wide_modals = [
        'user_agreement',
        'privacy_agreement',
        'license_agreement',
        'g2a_agreement',
        'rules',
      ];
      value = wide_modals.includes(value);
      commit('UPDATE_IS_MODAL_WIDE', value);
    },
    open_modal({ dispatch, getters }, event) {
      try {
        let target = event.target;
        let modal_target = null;
        modal_target = target.getAttribute('data-inner-select');
        if (!modal_target) {
          while (!modal_target) {
            target = target.parentNode;
            let el = target.getAttribute('data-inner-select');
            if (el) {
              modal_target = el;
            }
          }
        }
        dispatch('update_modal_target', modal_target);
        if (!getters.get_body) {
          dispatch('update_body');
        }
        dispatch('enable_modal');
        dispatch('update_show_modal', true);
      } catch (e) {
        console.error(e.message);
      }
    },
    close_modal({ commit, dispatch }) {
      dispatch('disable_modal');
      commit('UPDATE_SHOW_MODAL', false);
      router.push('/');
    },
    enable_modal({ getters }) {
      if (getters.get_body) {
        if (getters.get_body.classList && !getters.get_body.classList.contains('modal-active')) {
          getters.get_body.classList.add('modal-active');
        }
      }
    },
    disable_modal({ getters }) {
      if (getters.get_body) {
        if (getters.get_body.classList) {
          if (getters.get_body.classList.contains('modal-active')) {
            getters.get_body.classList.remove('modal-active');
          }
        }
      }
    },
    update_show_modal({ commit }, value) {
      commit('UPDATE_SHOW_MODAL', value);
    },
    update_modal_target({ commit, dispatch }, target) {
      dispatch('check_modal_dropdown', target);
      commit('UPDATE_MODAL_TARGET', target);
    },
    fetch_modal_links({ commit }) {
      let modalLinks = document.querySelectorAll('[data-inner-select]');
      commit('UPDATE_MODAL_LINKS', modalLinks);
    },
    modal_click_handler({ getters, dispatch }) {
      getters.get_modal_links.forEach(k => {
        k.addEventListener('click', e => {
          dispatch('open_modal', e);
        });
      });
    },
    modal_bg_click_handler({ dispatch, getters }) {
      getters.get_modal_bg.forEach(bg => {
        bg.addEventListener('click', e => {
          if ($(e.target).hasClass('modal')) {
            dispatch('close_modal');
            router.push({ name: 'Main' });
          }
        });
      });
    },
    fetch_modal_bg({ commit }) {
      let bg = document.querySelectorAll('[data-gw-modal]');
      commit('GET_MODAL_BG', bg);
    },
    async init_modal_env({ dispatch }) {
      await dispatch('fetch_modal_links');
      await dispatch('modal_click_handler');
      await dispatch('fetch_modal_bg');
      await dispatch('modal_bg_click_handler');
    },
    fetch_modal_selector_options({ commit }, options) {
      commit('UPDATE_MODAL_SELECTOR_OPTIONS', options);
    },
    preload_end({ commit }, ended) {
      commit('UPDATE_PRELOAD_ENDED', ended);
    },
    update_current_route({ commit }, value) {
      commit('UPDATE_CURRENT_ROUTE', value);
    },
  },
  /**
   * MUTATIONS
   */
  mutations: {
    UPDATE_SHOW_MODAL(state, modalState) {
      state.show_modal = modalState;
    },
    UPDATE_MODAL_LINKS(state, modalLinks) {
      state.modal_links = modalLinks;
    },
    GET_MODAL_BG(state, modalBg) {
      state.modal_bg = modalBg;
    },
    UPDATE_MODAL_TARGET(state, target) {
      state.modal_target = target;
    },
    UPDATE_MODAL_SELECTOR_OPTIONS(state, options) {
      state.modal_selector_options = options;
    },
    UPDATE_MODAL_DROPDOWN(state, dd) {
      state.modal_dropdown = dd;
    },
    UPDATE_IS_MODAL_WIDE(state, is_wide) {
      state.is_modal_wide = is_wide;
    },
    UPDATE_PRELOAD_ENDED(state, ended) {
      state.preload_ended = ended;
    },
    UPDATE_CURRENT_ROUTE(state, value) {
      state.current_route = value;
    },
  },
  /**
   * STATE
   */
  state: {
    current_route: null,
    preload_ended: false,
    show_modal: false,
    is_modal_wide: false,
    modal_target: null,
    modal_links: null,
    modal_bg: null,
    modal_dropdown: true,
    modal_selector_options: [
      {
        id: 'account',
        name: 'Account',
      },
      {
        id: 'login',
        name: 'Login',
      },
      {
        id: 'signup',
        name: 'Registration',
      },
      {
        id: 'files',
        name: 'Download files',
      },
      {
        id: 'shop',
        name: 'Shop',
      },
      {
        id: 'changePassword',
        name: 'Change password',
      },
      {
        id: 'restorePassword',
        name: 'Restore password',
      },
      {
        id: 'changeEmail',
        name: 'Change E-mail',
      },
      {
        id: 'unbind',
        name: 'Unbind HWID/IP',
      },
      {
        id: 'restoreUsername',
        name: 'Restore ID',
      },
      {
        id: 'vote',
        name: 'Vote',
      },
      {
        id: 'contacts',
        name: 'Contacts',
      },
      {
        id: 'support',
        name: 'Support',
      },
      {
        id: 'media',
        name: 'LIVE STREAM/VIDEO/GALLERY',
      },
      {
        id: 'languages',
        name: 'Languages',
      },
      {
        id: 'server',
        name: 'Server',
      },
      {
        id: 'stats',
        name: 'Statistics',
      },
      {
        id: 'media',
        name: 'Media',
      },
    ],
  },
  /**
   * GETTERS
   */
  getters: {
    get_current_route(state) {
      return state.current_route;
    },
    get_show_modal(state) {
      return state.show_modal;
    },
    get_modal_links(state) {
      return state.modal_links;
    },
    get_modal_bg(state) {
      return state.modal_bg;
    },
    get_modal_target(state) {
      return state.modal_target;
    },
    get_modal_selector_options(state) {
      return state.modal_selector_options;
    },
    is_modal_dropdown(state) {
      return state.modal_dropdown;
    },
    is_modal_wide(state) {
      return state.is_modal_wide;
    },
    is_preload_ended(state) {
      return state.preload_ended;
    },
  },
};
