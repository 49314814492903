<template>
  <div
    class="modal fadein"
    :class="{
      modal_hide: !show_modal,
      modal_none: !show_modal,
    }"
    data-gw-modal="services-modal"
  >
    <div class="modal__inner">
      <div
        :class="{
          'modal__heading-wide-decoration': is_modal_wide,
          'modal__heading-decoration': !is_modal_wide,
        }"
      ></div>
      <div
        :class="{
          'modal__footer-wide-decoration': is_modal_wide,
          'modal__footer-decoration': !is_modal_wide,
        }"
      ></div>
      <div class="modal__close" @click="close_modal"></div>
      <div
        :class="{
          modal__content: !is_modal_wide,
          'modal__content-wide': is_modal_wide,
        }"
      >
        <ModalSelectorProps
          v-show="is_modal_dropdown"
          :modal-selector-options="modal_selector_options"
        />
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import ModalSelectorProps from '@/components/Modal/ModalSelectorProps';

export default {
  name: 'Modals',
  data() {
    return {
      current_full_path: null,
      selector_options_filter_logged_in: [
        'account',
        'stats',
        'restoreUsername',
        'restorePassword',
        'changePassword',
        'changeEmail',
        'unbind',
        'shop',
        'vote',
        'media',
        'languages',
      ],
      selector_options_filter_logged_in_wide: [
        'account',
        'stats',
        'changePassword',
        'changeEmail',
        'unbind',
        'shop',
        'vote',
        'media',
        'languages',
        'server',
        'support',
        'contacts',
        'restoreUsername',
        'restorePassword',
        'files',
        'signup',
      ],
      selector_options_filter_logged_out: [
        'login',
        'stats',
        'changePassword',
        'changeEmail',
        'unbind',
        'shop',
        'vote',
        'media',
        'languages',
        'server',
        'support',
        'contacts',
        'restoreUsername',
        'restorePassword',
        'files',
        'signup',
      ],
    };
  },
  components: {
    ModalSelectorProps,
  },
  computed: {
    is_modal_dropdown() {
      return this.$store.getters.is_modal_dropdown;
    },
    is_modal_wide() {
      return this.$store.getters.is_modal_wide;
    },
    show_modal: {
      get() {
        return this.$store.getters.get_show_modal;
      },
      set(value) {
        this.$store.commit('UPDATE_SHOW_MODAL', value);
      },
    },
    current_route: {
      get() {
        return this.$store.getters.get_current_route;
      },
    },
    modal_selector_options: {
      get() {
        try {
          if (this.$store.getters.get_logged_in) {
            if (this.$route.path === '/account') {
              // account modal, small list
              return this.$store.getters.get_modal_selector_options.filter(
                menu => this.selector_options_filter_logged_in.indexOf(menu.id) !== -1
              );
            } else {
              // wide list
              return this.$store.getters.get_modal_selector_options.filter(
                menu => this.selector_options_filter_logged_in_wide.indexOf(menu.id) !== -1
              );
            }
          } else {
            return this.$store.getters.get_modal_selector_options.filter(
              menu => this.selector_options_filter_logged_out.indexOf(menu.id) !== -1
            );
          }
        } catch (e) {
          return this.$store.getters.get_modal_selector_options.filter(
            menu => this.selector_options_filter_logged_out.indexOf(menu.id) !== -1
          );
        }
      },
      set(target) {
        this.$store.commit('UPDATE_MODAL_SELECTOR_OPTIONS', target);
      },
    },
  },
  methods: {
    close_modal() {
      this.$store.dispatch('close_modal');
    },
  },
};
</script>

<style></style>
