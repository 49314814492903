import { __config } from '@/assets/js/config';
const luxon = require('luxon');
const $ = require('jquery');

export function serverTimeRender() {
  $('[data-start-time]').each(function (index) {
    let timeRender = new gw_timeUpdate(
      $(this),
      __config.server.startDate['server ' + (index + 1)],
      __config.startTime.tz
    );
    let update = function () {
      timeRender.update();
      setTimeout(() => {
        update();
      }, 1000);
    };
    update();
  });
}

class gw_timeUpdate {
  constructor(el, date, tz) {
    this.el = el;
    this.startTime = luxon.DateTime.fromSQL(date).setZone(tz);
    this.tz = tz;
  }
  update() {
    let dateNow = luxon.DateTime.now().setZone(
      Intl.DateTimeFormat().resolvedOptions().timeZone || this.tz
    );
    let coming = this.startTime < dateNow;
    let days = Math.abs(Math.round(dateNow.diff(this.startTime).as('days')));
    let hours = Math.abs(Math.round(dateNow.diff(this.startTime).as('hours') % 24));
    let minutes = Math.abs(Math.round(dateNow.diff(this.startTime).as('minutes') % 60));
    let seconds = Math.abs(Math.round(dateNow.diff(this.startTime).as('seconds') % 60));
    let days_name = days === 1 ? 'day' : 'days';
    let html = '';
    html += coming ? '<div class="life__title">' : '<div class="life__title life__title_new">';
    html += coming ? __config.startTime.title.lifeTime : __config.startTime.title.startIn;
    html += '</div>';
    html += '<div class="life__days">' + days + ' ' + days_name + '</div>';
    html += '<div class="life__time">';
    html += String(hours).padStart(2, '0');
    html += ':';
    html += String(minutes).padStart(2, '0');
    html += ':';
    html += String(seconds).padStart(2, '0');
    html += '</div>';
    this.el.html(html);
  }
}

export function serverInitCircleProgress() {
  let servers = $('.server');
  for (let i = 0; i < servers.length; i++) {
    const server = servers.eq(i);
    if (server.is('.server_status_on')) {
      let progress = server.find('.server__progress');
      progress.circleProgress({
        value: 1,
        thickness: 40,
        startAngle: 4.73,
        size: 120,
        fill: {
          image: __config.server.progressImg,
        },
        animation: {
          duration: 5000,
          easing: 'circleProgressEasing',
        },
      });
    }
  }
}
