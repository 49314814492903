import { __config } from '@/assets/js/config';
const $ = require('jquery');
window.$ = window.jQuery = $;

export function hidePreload(preloadElement, cb) {
  setTimeout(() => hidePreloadHandler(cb), __config.preload.time * 1000);
  if (__config.preload.hideByClick) {
    preloadElement.addEventListener('click', () => {
      hidePreloadHandler(cb);
    });
  }
}

function hidePreloadHandler(cb_preload_end) {
  $('.preload')
    .addClass('preload__fade')
    .fadeOut(100, function () {
      $('.preload').remove();
      cb_preload_end();
    });
}
