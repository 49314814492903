export default {
  /**
   * ACTIONS
   */
  actions: {
    initDownloads({ commit }) {
      const downloads = [
        {
          value: 'updater',
          name: 'UPDATER (exe)',
          src: 'https://updater.la2dream.com/DreamUpdater.exe',
          sources: null,
          types: null,
        },
        // {
        //   value: "patch",
        //   name: "SYSTEM, PATCH (CUSTOM)",
        //   src: null,
        //   sources: null,
        //   types: [
        //     // {
        //     //   value: "full",
        //     //   name: "[FULL] LA2DREAM SYSTEM, PATCH",
        //     //   sources: [
        //     //     {
        //     //       value: "https://updater.la2dream.com/La2Dream_ENG_RUS.zip",
        //     //       name: "LA2DREAM (zip)",
        //     //     },
        //     //     {
        //     //       value:
        //     //         "https://drive.google.com/drive/folders/0B4gKwywmsbrAbEMxZXFHOGx0aHM?resourcekey=0-fxdChkSrIlSrlipgngPftw",
        //     //       name: "GOOGLE (exe/zip)",
        //     //     },
        //     //     {
        //     //       value: "https://yadi.sk/d/o4h_0e-D3HEDGw",
        //     //       name: "YANDEX (exe/zip)",
        //     //     },
        //     //   ],
        //     // },
        //     {
        //       value: "clan",
        //       name: "[CLAN] LA2DREAM SYSTEM, PATCH",
        //       sources: [
        //         // {
        //         //   value: "https://updater.la2dream.com/La2Dream_forClan.zip",
        //         //   name: "LA2DREAM (zip)",
        //         // },
        //         {
        //           value:
        //             "https://drive.google.com/drive/folders/0B4gKwywmsbrAbEMxZXFHOGx0aHM?resourcekey=0-fxdChkSrIlSrlipgngPftw",
        //           name: "GOOGLE (exe/zip)",
        //         },
        //         // {
        //         //   value: "https://yadi.sk/d/o4h_0e-D3HEDGw",
        //         //   name: "YANDEX (exe/zip)",
        //         // },
        //       ],
        //     },
        //   ],
        // },
        {
          value: 'client',
          name: 'FULL GAME CLIENT',
          src: null,
          types: null,
          sources: [
            {
              value: 'https://upload.la2dream.com/La2Dream_Classic.zip',
              name: 'LA2DREAM (zip)',
            },
            {
              value: 'https://updater.la2dream.com/La2Dream_Classic.zip.torrent',
              name: 'TORRENT (zip)',
            },
            {
              value:
                'https://drive.google.com/file/d/1uzyEEGl1P3dG8gOVVjErS29R8nMH2A8F/view?usp=sharing',
              name: 'GOOGLE (zip/exe)',
            },
            /*{
              value:
                "https://mega.nz/file/HyI0gKCQ#Dq0mfcdzmDpuzkb7XTo89Ly0SEXAbxevn7n2X8OKJAU",
              name: "MEGA (zip)",
            },*/
          ],
        },
      ];
      commit('UPDATE_DOWNLOADS', downloads);
    },
    setDefaultOptions: ({ dispatch, getters }) => {
      let file = getters.getDownloads[getters.getFileIndex].value;
      dispatch('updateFile', file);

      let type = getters.getDownloads[getters.getTypeIndex].value;
      dispatch('updateType', type);

      let source = getters.getDownloads[getters.getFileIndex].sources[getters.getSourceIndex].value;
      dispatch('updateSource', source);
    },
    updateDownloads: ({ commit }, value) => commit('UPDATE_DOWNLOADS', value),
    updateFile: ({ commit }, value) => commit('UPDATE_FILE', value),
    updateType: ({ commit }, value) => commit('UPDATE_TYPE', value),
    updateSource: ({ commit }, value) => commit('UPDATE_SOURCE', value),
    updateFileIndex: ({ commit }, value) => commit('UPDATE_FILE_INDEX', value),
    updateTypeIndex: ({ commit }, value) => commit('UPDATE_TYPE_INDEX', value),
    updateSourceIndex: ({ commit }, value) => commit('UPDATE_SOURCE_INDEX', value),
  },
  /**
   * MUTATIONS
   */
  mutations: {
    UPDATE_DOWNLOADS(state, value) {
      state.downloads = value;
    },
    UPDATE_FILE: (state, value) => (state.file = value),
    UPDATE_TYPE: (state, value) => (state.type = value),
    UPDATE_SOURCE: (state, value) => (state.source = value),
    UPDATE_FILE_INDEX: (state, value) => (state.fileIndex = value),
    UPDATE_TYPE_INDEX: (state, value) => (state.typeIndex = value),
    UPDATE_SOURCE_INDEX: (state, value) => (state.sourceIndex = value),
  },
  /**
   * STATE
   */
  state: {
    downloads: null,
    file: null,
    type: null,
    source: null,
    fileIndex: 1,
    typeIndex: 0,
    sourceIndex: 0,
  },
  /**
   * GETTERS
   */
  getters: {
    getDownloads: state => state.downloads,
    getFile: state => state.file,
    getType: state => state.type,
    getSource: state => state.source,
    getFileIndex: state => state.fileIndex,
    getTypeIndex: state => state.typeIndex,
    getSourceIndex: state => state.sourceIndex,
  },
};
