export const __config = {
  preload: {
    time: 2,
    hideByClick: false,
  },
  translate: {
    lang: 'en',
    // domain: "la2dream.com",
    folder: 'assets/images/lang',
  },
  recaptcha_sitekey: '6LdRjU8UAAAAAOMOtoNvKZjK88vXmJ7FonxXu7RN',
  server: {
    default_server_id: 2,
    progressImg: require('@/assets/images/servers/server__load_progress_on.webp'),
    maxOnline: {
      'server 1': 1,
      'server 2': 1,
      'server 3': 1,
      'server 4': 1,
      'server 5': 1,
      'server 6': 1,
    },
    startDate: {
      'server 1': '2021-05-29T20:00:00+03:00',
      'server 2': '2023-05-27T20:00:00+03:00',
      'server 3': '2022-12-03T20:00:00+03:00',
      'server 4': '2023-04-01T20:00:00+03:00',
      'server 5': '2023-07-29T20:00:00+03:00',
    },
    server_desc_by_id: [
      'https://forum.la2dream.com/topic/14348-la2dream-x50-server-updated-description-start-october-1/',
      'https://forum.la2dream.com/topic/15323-la2dream-x50-server-updated-description-start-may-27-2023/',
      'https://forum.la2dream.com/topic/14552-classic-interlude-x5-description/',
      'https://forum.la2dream.com/topic/15083-interlude-pvp-x500-description/',
      'https://forum.la2dream.com/topic/15083-interlude-pvp-x500-description/',
    ],
  },
  startTime: {
    tz: 'Europe/Moscow',
    title: {
      lifeTime: 'Life time',
      startIn: 'JANUARY 29',
    },
  },
  sliders: {
    snews: {
      init: true,
      autoplay: true,
      pauseOnHover: true,
      autoplayDelay: 10000,
    },
  },
  tabs: {
    tabDef: 1,
  },
};
