<template>
  <div class="preload" ref="preload">
    <img :src="imgPreload" alt="preload" class="preload__logo" />
  </div>
</template>

<script>
import { hidePreload } from '@/assets/js/preLoad';

export default {
  name: 'Preload',
  data() {
    return {
      imgPreload: require('@/assets/images/logo/logo.webp'),
      current_timestamp: new Date().getTime(),
    };
  },
  async beforeMount() {
    this.$store.dispatch('init_config');
    this.$store.dispatch('init_forum');

    if (!this.news || !this.events || !this.topics || this.forum_expired < this.current_timestamp) {
      this.$store.dispatch('fetch_forum');
    }

    if (!this.config || this.config_expired < this.current_timestamp) {
      this.$store.dispatch('fetch_config');
    }

    if (!this.streams || this.streams_expired < this.current_timestamp) {
      this.$store.dispatch('fetch_streams');
    } else {
      this.$store.commit('UPDATE_FETCHING_STREAMS', false);
    }
  },
  computed: {
    streams() {
      return this.$store.getters.get_media_streams;
    },
    config() {
      return this.$store.getters.get_config;
    },
    news() {
      return this.$store.getters.get_media_news;
    },
    events() {
      return this.$store.getters.get_media_events;
    },
    topics() {
      return this.$store.getters.get_media_topics;
    },
    streams_expired() {
      return this.$store.getters.get_streams_expired;
    },
    config_expired() {
      return this.$store.getters.get_config_expired;
    },
    forum_expired() {
      return this.$store.getters.get_forum_expired;
    },
  },
  methods: {
    cb_preload_end() {
      this.$store.dispatch('preload_end', true);
      this.$emit('preloadFinished');
    },
  },
  mounted() {
    hidePreload(this.$refs.preload, this.cb_preload_end);
  },
};
</script>

<style>
body {
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0;
}

/* preload */
.preload {
  background-color: #110f12;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;
}

/*
.fadePreload {
  animation: fade 0.5s ease-out;
}
*/

@keyframes fade {
  from {
    opacity: 1;
    transform: scale(1.25);
  }
  to {
    opacity: 0;
    transform: scale(1);
  }
}
</style>
