import { createStore } from 'vuex';

import config from './modules/config';
import dom from './modules/dom';
import media from './modules/media';
import modal from './modules/modal';
import server from './modules/server';
import payment from './modules/payment';
import signup from './modules/signup';
import downloads from './modules/downloads';
import language from './modules/language';
import statistics from './modules/statistics';
import auth from './modules/auth';
import footer from './modules/footer';

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    config,
    auth,
    dom,
    media,
    modal,
    server,
    payment,
    signup,
    downloads,
    language,
    statistics,
    footer,
  },
});
