<template>
  <div class="bar">
    <template v-if="isL2oopsBannerEnabled">
      <a href="https://l2oops.com" style="z-index: 99999" target="_blank"
        ><img
          src="@/assets/images/banner/black_l2oops_mini.png"
          width="122"
          height="31"
          alt="New servers Lineage 2"
          title="New servers Lineage 2"
      /></a>
    </template>
    <FunctionBtn v-for="(btn, index) in function_buttons" :key="index" :btn="btn"></FunctionBtn>
    <LanguageSwitcher></LanguageSwitcher>
    <BurgerMenu></BurgerMenu>
  </div>
</template>

<script>
import FunctionBtn from '@/components/Header/FunctionBtn';
import LanguageSwitcher from '@/components/Header/LanguageSwitcher';
import BurgerMenu from '@/components/Header/BurgerMenu';

export default {
  name: 'RightBar',
  components: { BurgerMenu, LanguageSwitcher, FunctionBtn },
  data() {
    return {
      l2oopsConfigValue: null,
    };
  },
  async mounted() {
    this.l2oopsConfigValue = await this.$store.dispatch('find_config_item', 'enable_l2oops');
  },
  computed: {
    isL2oopsBannerEnabled() {
      if (!this.l2oopsConfigValue) return false;
      return (
        Boolean(Number(this.l2oopsConfigValue)) ||
        String(this.l2oopsConfigValue).toLowerCase() === 'true'
      );
    },
    function_buttons() {
      if (this.$store.getters.get_logged_in) {
        return [
          {
            imgSrc: require('@/assets/images/btns/ico-acc.png'),
            alt: 'account',
            route: '/account',
            routeName: 'account',
          },
        ];
      } else {
        return [
          {
            imgSrc: require('@/assets/images/btns/ico-acc.png'),
            alt: 'login',
            route: '/login',
            routeName: 'login',
          },
        ];
      }
    },
  },
};
</script>

<style scoped></style>
