import $ from 'jquery';

export default {
  /**
   * ACTIONS
   */
  actions: {
    init_footer({ commit }) {
      commit('INIT_FOOTER');
    },
    update_footer({ commit }, footer) {
      commit('UPDATE_FOOTER', footer);
    },
    toggle_footer({ commit, state }) {
      if (typeof Event === 'function') {
        // For modern browsers that support the Event constructor
        const ev = new Event('resize');
        window.dispatchEvent(ev);
      } else {
        // For older browsers
        let resizeEvent = window.document.createEvent('UIEvents');
        resizeEvent.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(resizeEvent);
      }

      let footer = null;
      footer = !state.footer ? 1 : 0;
      try {
        // const wiw = window.innerWidth;
        const wih = window.innerHeight;
        if (wih < 1140) {
          if ($('.footer-hide').length > 0) {
            $('.stream_fullscreen').removeClass('stream_fullscreen_visible');
          }
        }

        $('[data-toggle-footer-container]').slideToggle(this.speed, () => {
          $('body').toggleClass('footer-hide');
          $(window).trigger('resize');
        });

        commit('UPDATE_FOOTER', footer);
      } catch (e) {
        console.log(e.message);
      }
    },
  },
  mutations: {
    UPDATE_FOOTER_INIT(state) {
      state.footer_init = true;
    },
    INIT_FOOTER(state) {
      let footer = null;

      if (state.footer_init) return;

      try {
        footer = parseInt(localStorage.footer) || Number(state.footer_default);
        state.footer = footer;
      } catch (e) {
        footer = Number(state.footer_default);
        state.footer = footer;
      } finally {
        this.commit('UPDATE_FOOTER', footer);
        this.commit('UPDATE_FOOTER_INIT');
      }
    },
    UPDATE_FOOTER(state, footer) {
      try {
        localStorage.footer = footer;
        state.footer = footer;
      } catch (e) {
        state.footer = footer;
      }
    },
  },
  state: {
    footer_init: false,
    footer: null,
    footer_default: 0,
  },
  getters: {
    get_footer: state => localStorage.footer || state.footer,
    get_footer_default: state => state.footer_default,
  },
};
