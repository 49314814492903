<template>
  <div class="section" data-section="mid">
    <div class="container">
      <div class="mid">
        <NewsSlider></NewsSlider>
        <Tabs></Tabs>
      </div>
    </div>
  </div>
</template>

<script>
import NewsSlider from '@/components/Body/Media/Slider/NewsSlider';
import Tabs from '@/components/Body/Media/Tabs/Tabs';
export default {
  name: 'Media',
  components: { Tabs, NewsSlider },
};
</script>

<style scoped></style>
