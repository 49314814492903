<template>
  <template v-if="!link.external">
    <router-link
      :to="{ name: link.routeName, params: { id: server_id } }"
      :class="link.class"
      :data-inner-select="link.id"
      @click="this.$store.dispatch('open_modal', $event)"
    >
      <div class="hint__content">{{ link.name }}</div>
    </router-link>
  </template>
  <template v-else>
    <template v-if="link.id === 'description'">
      <a :href="descLink" target="_blank" :class="link.class">
        <div class="hint__content">{{ link.name }}</div>
      </a>
    </template>
    <template v-else>
      <a :href="link.href" target="_blank" :class="link.class">
        <div class="hint__content">{{ link.name }}</div>
      </a>
    </template>
  </template>
</template>

<script>
import { __config } from '@/assets/js/config';

export default {
  name: 'ServerLinks',
  data() {
    return {
      config: __config,
    };
  },
  props: {
    link: {
      type: Object,
      required: true,
    },
    server_id: {
      type: Number,
    },
    descLink: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
