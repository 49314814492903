import axios from 'axios';
import cfg_axios from '@/config/cfg_axios';

const api_uri = `${process.env.VUE_APP_API_BASE_URL}/api`;
const config = { headers: cfg_axios.headers };

export default {
  actions: {
    init_stats_default_server({ dispatch, getters }, server_id) {
      dispatch('update_stats_default_server', server_id || getters.get_default_server);
    },
    update_stats_default_server({ commit }, server) {
      commit('UPDATE_STATS_DEFAULT_SERVER', server);
    },
    init_top_level({ commit }) {
      try {
        if (localStorage.top_level && localStorage.top_level_expired) {
          commit('UPDATE_TOP_LEVEL', JSON.parse(localStorage.top_level));
          commit('UPDATE_TOP_LEVEL_EXPIRED', localStorage.top_level_expired);
        }
      } catch (e) {
        // console.log(e.message);
      }
    },
    init_top_pvp({ commit }) {
      try {
        if (localStorage.top_pvp && localStorage.top_pvp_expired) {
          commit('UPDATE_TOP_PVP', JSON.parse(localStorage.top_pvp));
          commit('UPDATE_TOP_PVP_EXPIRED', localStorage.top_pvp_expired);
        }
      } catch (e) {
        // console.log(e.message);
      }
    },
    init_top_pk({ commit }) {
      try {
        if (localStorage.top_pk && localStorage.top_pk_expired) {
          commit('UPDATE_TOP_PK', JSON.parse(localStorage.top_pk));
          commit('UPDATE_TOP_PK_EXPIRED', localStorage.top_pk_expired);
        }
      } catch (e) {
        // console.log(e.message);
      }
    },
    init_top_clan({ commit }) {
      try {
        if (localStorage.top_clan && localStorage.top_clan_expired) {
          commit('UPDATE_TOP_CLAN', JSON.parse(localStorage.top_clan));
          commit('UPDATE_TOP_CLAN_EXPIRED', localStorage.top_clan_expired);
        }
      } catch (e) {
        // console.log(e.message);
      }
    },
    init_top_castle({ commit }) {
      try {
        if (localStorage.top_castle && localStorage.top_castle_expired) {
          commit('UPDATE_TOP_CASTLE', JSON.parse(localStorage.top_castle));
          commit('UPDATE_TOP_CASTLE_EXPIRED', localStorage.top_castle_expired);
        }
      } catch (e) {
        // console.log(e.message);
      }
    },
    init_top_epic({ commit }) {
      try {
        if (localStorage.top_epic && localStorage.top_epic_expired) {
          commit('UPDATE_TOP_EPIC', JSON.parse(localStorage.top_epic));
          commit('UPDATE_TOP_EPIC_EXPIRED', localStorage.top_epic_expired);
        }
      } catch (e) {
        // console.log(e.message);
      }
    },
    async fetch_top_level({ commit }) {
      await axios.get(api_uri + '/v3/stats/top_level', config).then(response => {
        commit('UPDATE_TOP_LEVEL', response.data);
        commit(
          'UPDATE_TOP_LEVEL_EXPIRED',
          new Date(new Date().getTime() + 5 * 60 * 1000).getTime()
        );
      });
    },
    async fetch_top_pvp({ commit }) {
      await axios.get(api_uri + '/v3/stats/top_pvp', config).then(response => {
        commit('UPDATE_TOP_PVP', response.data);
        commit('UPDATE_TOP_PVP_EXPIRED', new Date(new Date().getTime() + 5 * 60 * 1000).getTime());
      });
    },
    async fetch_top_pk({ commit }) {
      await axios.get(api_uri + '/v3/stats/top_pk', config).then(response => {
        commit('UPDATE_TOP_PK', response.data);
        commit('UPDATE_TOP_PK_EXPIRED', new Date(new Date().getTime() + 5 * 60 * 1000).getTime());
      });
    },
    async fetch_top_clan({ commit }) {
      await axios.get(api_uri + '/v3/stats/top_clan', config).then(response => {
        commit('UPDATE_TOP_CLAN', response.data);
        commit('UPDATE_TOP_CLAN_EXPIRED', new Date(new Date().getTime() + 5 * 60 * 1000).getTime());
      });
    },
    async fetch_top_castle({ commit }) {
      await axios.get(api_uri + '/v3/stats/top_castle', config).then(response => {
        commit('UPDATE_TOP_CASTLE', response.data);
        commit(
          'UPDATE_TOP_CASTLE_EXPIRED',
          new Date(new Date().getTime() + 5 * 60 * 1000).getTime()
        );
      });
    },
    async fetch_top_epic({ commit }) {
      await axios.get(api_uri + '/v3/stats/top_epic', config).then(response => {
        commit('UPDATE_TOP_EPIC', response.data);
        commit(
          'UPDATE_TOP_EPIC_EXPIRED',
          new Date(new Date().getTime() + 60 * 60 * 1000).getTime()
        );
      });
    },
  },
  mutations: {
    UPDATE_STATS_DEFAULT_SERVER(state, server) {
      state.stats_default_server = server;
    },
    UPDATE_TOP_LEVEL(state, value) {
      try {
        localStorage.top_level = JSON.stringify(value);
        state.top_level = value;
      } catch (e) {
        state.top_level = value;
      }
    },
    UPDATE_TOP_PVP(state, value) {
      try {
        localStorage.top_pvp = JSON.stringify(value);
        state.top_pvp = value;
      } catch (e) {
        state.top_pvp = value;
      }
    },
    UPDATE_TOP_PK(state, value) {
      try {
        localStorage.top_pk = JSON.stringify(value);
        state.top_pk = value;
      } catch (e) {
        state.top_pk = value;
      }
    },
    UPDATE_TOP_CLAN(state, value) {
      try {
        localStorage.top_clan = JSON.stringify(value);
        state.top_clan = value;
      } catch (e) {
        state.top_clan = value;
      }
    },
    UPDATE_TOP_CASTLE(state, value) {
      try {
        localStorage.top_castle = JSON.stringify(value);
        state.top_castle = value;
      } catch (e) {
        state.top_castle = value;
      }
    },
    UPDATE_TOP_EPIC(state, value) {
      try {
        localStorage.top_epic = JSON.stringify(value);
        state.top_epic = value;
      } catch (e) {
        state.top_epic = value;
      }
    },
    UPDATE_TOP_LEVEL_EXPIRED(state, value) {
      try {
        localStorage.top_level_expired = value;
        state.top_level_expired = value;
      } catch (e) {
        state.top_level_expired = value;
      }
    },
    UPDATE_TOP_PVP_EXPIRED(state, value) {
      try {
        localStorage.top_pvp_expired = value;
        state.top_pvp_expired = value;
      } catch (e) {
        state.top_pvp_expired = value;
      }
    },
    UPDATE_TOP_PK_EXPIRED(state, value) {
      try {
        localStorage.top_pk_expired = value;
        state.top_pk_expired = value;
      } catch (e) {
        state.top_pk_expired = value;
      }
    },
    UPDATE_TOP_CLAN_EXPIRED(state, value) {
      try {
        localStorage.top_clan_expired = value;
        state.top_clan_expired = value;
      } catch (e) {
        state.top_clan_expired = value;
      }
    },
    UPDATE_TOP_CASTLE_EXPIRED(state, value) {
      try {
        localStorage.top_castle_expired = value;
        state.top_castle_expired = value;
      } catch (e) {
        state.top_castle_expired = value;
      }
    },
    UPDATE_TOP_EPIC_EXPIRED(state, value) {
      try {
        localStorage.top_epic_expired = value;
        state.top_epic_expired = value;
      } catch (e) {
        state.top_epic_expired = value;
      }
    },
  },
  state: {
    top_level: [],
    top_level_expired: null,
    top_pvp: [],
    top_pvp_expired: null,
    top_pk: [],
    top_pk_expired: null,
    top_clan: [],
    top_clan_expired: null,
    top_castle: [],
    top_castle_expired: null,
    top_epic: [],
    top_epic_expired: null,
    stats_default_server: 3,
  },
  getters: {
    get_stats_default_server: state => state.stats_default_server,
    level: state => state.top_level,
    level_expired: state => state.top_level_expired,
    pvp: state => state.top_pvp,
    pvp_expired: state => state.top_pvp_expired,
    pk: state => state.top_pk,
    pk_expired: state => state.top_pk_expired,
    clan: state => state.top_clan,
    clan_expired: state => state.top_clan_expired,
    castle: state => state.top_castle,
    castle_expired: state => state.top_castle_expired,
    epic: state => state.top_epic,
    epic_expired: state => state.top_epic_expired,
  },
};
