import { __config } from '@/assets/js/config';
const $ = require('jquery');

export function initTabs() {
  let btns = $('.tabs__btns .tabs__btn').not('.tabs__btn_link');
  let tabs = $('.tabs__content .tabs__tab');

  btns
    .on('click', function () {
      const __this = $(this);
      tabs.hide();
      tabs.eq(__this.index()).show();
      btns.removeClass('tabs__btn_active');
      __this.addClass('tabs__btn_active');
    })
    .eq((__config.tabs.tabDef || 1) - 1)
    .trigger('click');
}
